import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28')
];

export const server_loads = [0,2,3];

export const dictionary = {
		"/(commerce)": [4,[2]],
		"/(user)/account": [~28],
		"/(public)/auth/access": [~20],
		"/(public)/auth/callback": [~21],
		"/(public)/auth/signin": [~22],
		"/(public)/auth/signout": [~23],
		"/(public)/auth/signup": [~24],
		"/(public)/closed": [25],
		"/(commerce)/menu": [~5],
		"/(commerce)/menu/[productId=product]": [~6],
		"/(commerce)/product/[productId=product]": [~7],
		"/(commerce)/reports": [~8,[2]],
		"/(public)/request": [26],
		"/(commerce)/settings": [~9,[2]],
		"/(commerce)/table/[tableId=table]/order": [~10,[2]],
		"/(commerce)/tags": [~11,[2]],
		"/(commerce)/tools": [~12,[2]],
		"/(public)/waiter": [27],
		"/(order)/[orderId=order]": [13,[3]],
		"/(order)/[orderId=order]/expired": [15,[3]],
		"/(order)/[orderId=order]/pay": [16,[3]],
		"/(order)/[orderId=order]/receipt": [17,[3]],
		"/(order)/[orderId=order]/review": [18,[3]],
		"/(order)/[orderId=order]/status": [19,[3]],
		"/(order)/[orderId=order]/[productId=product]": [~14,[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';